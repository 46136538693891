import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Button, Empty, Space, Select } from "antd";

import { Bar } from "@ant-design/charts";

import moment from "moment";

import { ReloadOutlined } from "@ant-design/icons";

import { getStatisticsPipeSizeList } from "../../features/performance/performanceSlice";

import PickerWithType from "../../libs/components/PickerWithType";
import SelectDateType from "../../libs/components/SelectDateType";
import { NOVARC_PRODUCT } from "../../libs/constants";
import { GetDateRange, getPipeSchedule, normalizeRobotName } from "../../libs/utils";

const getPipeSize = (data) => {
  if (data && data.length > 0) {
    return data.map((item) => {
      return {
        ...item,
        pipe_type: `${item.pipe_size_inches}${
          item.units_system
            ? item.units_system === "imperial"
              ? " inches"
              : " cm"
            : ""
        } sched ${getPipeSchedule(item.pipe_schedule)}`,
      };
    });
  } else {
    return [];
  }
};

const today = moment();
const lastWeek = moment().subtract(7, "days");

function PipeSizesStatistics(props) {
  const dispatch = useDispatch();

  const statistics = useSelector((state) => state.performance.statistics) || [];

  const [dateValues, setDateValues] = useState(null);
  const [selectedValues, setSelectedValues] = useState(null);
  const [type, setType] = useState("range");

  useEffect(() => {
    dispatch(
      getStatisticsPipeSizeList(null, {
        start: lastWeek.format("YYYY-MM-DD"),
        end: today.format("YYYY-MM-DD"),
      })
    );
  }, [dispatch]);

  const data = getPipeSize(statistics);

  return (
    <>
      <Space>
        <b>Statistics of Pipe Welded on</b>

        <SelectDateType value={type} onChange={setType}></SelectDateType>
        <PickerWithType
          type={type}
          defaultValue={[lastWeek, today]}
          onChange={(value) => setDateValues(value)}
        />
        <Button
          disabled={!dateValues}
          onClick={() => {
            const dateRange = GetDateRange(type, dateValues);

            dispatch(
              getStatisticsPipeSizeList(null, {
                start: dateRange[0],
                end: dateRange[1],
              })
            );
          }}
          icon={<ReloadOutlined />}
        ></Button>

        <Select
          style={{ minWidth: 200 }}
          mode="multiple"
          placeholder={`Filter by ${NOVARC_PRODUCT} number`}
          options={data
            .map((item) => item.robot_id)
            .filter((v, i, a) => a.indexOf(v) === i)
            .map((item) => ({
              label: normalizeRobotName(item),
              value: item,
            }))}
          onChange={(values) => {
            setSelectedValues(values);
          }}
        ></Select>
      </Space>

      <div>
        {statistics && statistics.length > 0 ? (
          <Bar
            {...{
              data:
                !selectedValues || selectedValues.length === 0
                  ? data
                  : data.filter((v, i, a) => {
                      return selectedValues.includes(v.robot_id);
                    }),
              xField: "count",
              yField: "pipe_type",
              isStack: true,
              seriesField: "robot_id",
              // color: "#1890ff",
              legend: false,
              barWidthRatio: 0.9,
              maxBarWidth: 20,
              label: {
                position: "middle",
                formatter: function (v) {
                  return v.count;
                },
              },
              xAxis: {
                label: {
                  formatter: function formatter(v) {
                    return v;
                  },
                },
              },
              tooltip: {
                formatter: (datum) => {
                  return {
                    name: normalizeRobotName(datum.robot_id),
                    value: datum.count,
                  };
                },
              },
            }}
          />
        ) : (
          <Empty />
        )}
      </div>
    </>
  );
}
export default PipeSizesStatistics;
