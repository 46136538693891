import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Alert, Form, Input, Button, Typography, Select } from "antd";

import {
  // LockOutlined,
  MailOutlined,
  TagOutlined,
  UserOutlined,
  GlobalOutlined,
  TeamOutlined,
} from "@ant-design/icons";

import { registerUser } from "./authSlice";
import { getGroupsList } from "../admin/adminSlice";

import LoadingPage from "../../components/LoadingPage";
import { NOVARC_PRODUCT } from "../../libs/constants";

const { Title } = Typography;

const { Option, OptGroup } = Select;

const CreateUser = (props) => {
  const registering = useSelector((state) => state.auth.registering);
  const registeringError = useSelector((state) => state.auth.registeringError);
  const groups = useSelector((state) => state.admin.groups);

  const [submitted, setSubmitted] = useState(false);

  const { form } = props;

  const dispatch = useDispatch();

  const handleSubmit = ({ email, name, company, group, role }) => {
    dispatch(registerUser(email, name, company, group, role));
    setSubmitted(true);
  };

  const onClose = (e) => {
    console.log(e, "Closing");
  };

  useEffect(() => {
    if (!groups) {
      dispatch(getGroupsList());
    }
  }, [dispatch, groups]);

  if (!groups) {
    return <LoadingPage />;
  }

  return (
    <Form
      form={form}
      name="register"
      className="register-form"
      onFinish={handleSubmit}
      style={{ marginTop: 40 }}
    >
      <Title level={3}>Create a new user</Title>
      <p>
        Create a new user account on the dashboard and send an email invitation.
      </p>
      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            message: "Please input user's Email!",
          },
          {
            type: "email",
            message: "Invalid Email Format!",
          },
        ]}
      >
        <Input prefix={<MailOutlined />} placeholder="Email" />
      </Form.Item>
      <Form.Item
        name="name"
        rules={[
          { required: true, message: "Please input user's Name!" },
          {
            type: "string",
            min: 2,
            max: 30,
            message: "Name should be between 6 to 30 characters",
          },
        ]}
      >
        <Input prefix={<UserOutlined />} placeholder="Name" />
      </Form.Item>

      <Form.Item
        name="company"
        rules={[{ required: true, message: "Please specify user's Company!" }]}
      >
        <Select
          style={{ textAlign: "justify" }}
          placeholder={
            <>
              <GlobalOutlined style={{ color: "rgba(0, 0, 0, 0.85)" }} />
              {"  "}Company
            </>
          }
        >
          {groups
            .filter((item) => !item.name.includes(`${NOVARC_PRODUCT}-`))
            .map((item) => (
              <Option value={item.name}>{item.name}</Option>
            ))}
        </Select>
        {/* <Input prefix={<TagOutlined />} placeholder="Role" /> */}
      </Form.Item>

      <Form.Item
        name="group"
        rules={[{ required: true, message: "Please specify user's Group!" }]}
      >
        <Select
          style={{ textAlign: "justify" }}
          placeholder={
            <>
              <TeamOutlined style={{ color: "rgba(0, 0, 0, 0.85)" }} />
              {"  "}Group
            </>
          }
        >
          {groups
            .map((item) => (
              <Option value={item.name}>{item.name}</Option>
            ))}
        </Select>
        {/* <Input prefix={<TagOutlined />} placeholder="Role" /> */}
      </Form.Item>

      <Form.Item
        name="role"
        rules={[{ required: true, message: "Please specify user's Role!" }]}
      >
        <Select
          style={{ textAlign: "justify" }}
          placeholder={
            <>
              <TagOutlined style={{ color: "rgba(0, 0, 0, 0.85)" }} />
              {"  "}Role
            </>
          }
        >
          <OptGroup label="Support">
            <Option value="support">Support</Option>
            <Option value="admin">Admin</Option>
          </OptGroup>
          <OptGroup label="Customer">
            <Option value="customer">Customer</Option>
          </OptGroup>
        </Select>
        {/* <Input prefix={<TagOutlined />} placeholder="Role" /> */}
      </Form.Item>

      {!!registeringError && (
        <React.Fragment>
          <Alert
            message={registeringError}
            type="error"
            closable
            onClose={onClose}
          />
          <br />
        </React.Fragment>
      )}

      {!registeringError && submitted && (
        <React.Fragment>
          <Alert
            message={"Successfully create user account & Invitation sent"}
            type="success"
            closable
            onClose={onClose}
          />
          <br />
        </React.Fragment>
      )}

      <Form.Item>
        <Button block type="primary" htmlType="submit" loading={registering}>
          Invite User
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateUser;
