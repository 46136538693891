import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Dropdown, Layout, Menu } from "antd";

import { MenuOutlined } from "@ant-design/icons";
import { ProfileIcon } from "./icons/NovarcIcons";

import { push } from "connected-react-router";

import { authUser, logoutUser } from "../features/auth/authSlice";

import {
  ROUTER_ADMIN_ROOT,
  ROUTER_ADMIN_USERS_NEW,
  ROUTER_PROFILE_PASSWORD,
  ROUTER_WRIKE_KPI,
  ROUTER_PERFORMANCE_ROOT,
  ROUTER_PROFILE_SETTING,
  isNovAI,
} from "../libs/constants";
import { isAdmin, isSupport } from "../libs/auth";

const { Header } = Layout;

function handleMenuClick() {}

function LayoutHeader(props) {
  const user = useSelector((state) => state.auth.user);

  const dispatch = useDispatch();

  const handleSubmit = () => {
    dispatch(logoutUser());
  };

  const handleProfile = () => {
    dispatch(push(ROUTER_PROFILE_PASSWORD));
  };

  // eslint-disable-next-line
  const handleSettings = () => {
    dispatch(push(ROUTER_PROFILE_SETTING));
  };

  const handleCreateUser = () => {
    dispatch(push(ROUTER_ADMIN_USERS_NEW));
  };

  const handleWrikeKPI = () => {
    dispatch(push(ROUTER_WRIKE_KPI));
  };

  const handleAdminDashboard = () => {
    dispatch(push(ROUTER_ADMIN_ROOT));
  };

  const handlePerformanceDetail = () => {
    dispatch(push(ROUTER_PERFORMANCE_ROOT));
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item onClick={handleProfile}>Change Password</Menu.Item>
      {/* <Menu.Item onClick={handleSettings}>Settings</Menu.Item> */}
      <Menu.Item onClick={handleSubmit}>Sign Out</Menu.Item>
    </Menu>
  );

  if (!user) {
    dispatch(authUser());
    return null;
  }

  return (
    <Header className="site-layout-background" style={{ padding: 0 }}>
      <div style={{ float: "right", color: "white", padding: "0 16px" }}>
        <Dropdown overlay={menu} size="small" style={{}}>
          <div
            className="ant-dropdown-link"
            onClick={(e) => e.preventDefault()}
            style={{ cursor: "pointer" }}
          >
            <ProfileIcon />
            {/* <Avatar
              style={{ verticalAlign: "middle" }}
              size="small"
              icon={<ProfileIcon />}
            >
              {user.name[0]}
            </Avatar> */}
            <span style={{ paddingLeft: 8 }}>{user.name}</span>
          </div>
        </Dropdown>
      </div>
      {(isAdmin() || isSupport()) && (
        <div style={{ float: "right", color: "white", padding: "0" }}>
          <Dropdown
            overlay={
              <Menu onClick={handleMenuClick}>
                {isAdmin() && (
                  <>
                    <Menu.Item onClick={handleAdminDashboard}>Admin</Menu.Item>
                    <Menu.Item onClick={handleCreateUser}>
                      Create User
                    </Menu.Item>
                  </>
                )}
                {!isNovAI() && (
                  <Menu.Item onClick={handlePerformanceDetail}>
                    Performance Details
                  </Menu.Item>
                )}
                {!isNovAI() && (
                  <Menu.Item onClick={handleWrikeKPI}>Wrike KPI</Menu.Item>
                )}
              </Menu>
            }
            size="small"
            style={{}}
          >
            <div
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
              style={{ cursor: "pointer" }}
            >
              <MenuOutlined />
            </div>
          </Dropdown>
        </div>
      )}
      {/* <Button onClick={handleSubmit}>SignOut</Button> */}
    </Header>
  );
}

export default LayoutHeader;
