import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Alert, Button, Form, Input, Typography } from "antd";
import { MailOutlined } from "@ant-design/icons";

import logo from "../../assets/novarc-logo.black.svg";

import { recoverUserPassword } from "./authSlice";
import { REGEX_EMAIL, NOVARC_PRODUCT } from "../../libs/constants";

const { Title } = Typography;

const PasswordRecovery = (props) => {
  const recovering = useSelector((state) => state.auth.recovering);
  const recoveringError = useSelector((state) => state.auth.recoveringError);

  const [recoveringDone, setRecoveringDone] = useState(false);

  const dispatch = useDispatch();

  const handleSubmit = ({ email }) => {
    dispatch(recoverUserPassword(email));
    setRecoveringDone(true);
  };

  console.log("recoveringError: ", recoveringError);
  const onClose = (e) => {
    console.log(e, "Closing");
    setRecoveringDone(false);
  };

  return (
    <div
      style={{
        textAlign: "center",
        verticalAlign: "middle",
        maxWidth: 400,
        top: "50%",
        left: "50%",
        position: "absolute",
        MsTransform: "translate(-50%, -50%)",
        transform: "translate(-50%, -50%)",
      }}
    >
      <img
        className="logo"
        alt="logo"
        src={logo}
        style={{ height: 80, margin: "12px" }}
      />

      <p>Novarc NovSync Dashboard for {NOVARC_PRODUCT}</p>

      <Form
        name="login"
        className="login-form"
        onFinish={handleSubmit}
        style={{ marginTop: 40 }}
      >
        <Title level={4}>Reset Password</Title>
        <p>To reset your password, please provide your email.</p>

        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: "Please enter your email",
            },
            {
              type: "email",
              message: "Please enter your email address in valid format",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || REGEX_EMAIL.test(value)) {
                  return Promise.resolve();
                }

                return Promise.reject(
                  new Error("Please enter your email address in valid format.")
                );
              },
            }),
          ]}
        >
          <Input prefix={<MailOutlined />} placeholder="Email" />
        </Form.Item>

        {!!recoveringError && (
          <React.Fragment>
            <Alert
              message={`Sorry, Something went wrong!
              Please try again later`}
              type="error"
              closable
              onClose={onClose}
            />
            <br />
          </React.Fragment>
        )}
        {recoveringDone && !recoveringError && (
          <React.Fragment>
            <Alert
              message={`We have sent a password reset instruction to your email addresses.
              Please check your inbox to continue`}
              type="success"
              closable
              onClose={onClose}
            />
            <br />
          </React.Fragment>
        )}

        <Form.Item>
          <Button
            block
            type="primary"
            htmlType="submit"
            loading={recovering}
            disabled={!recoveringError && recoveringDone}
          >
            Send Reset Instruction
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default PasswordRecovery;
