import React, { useState } from "react";

import { Alert, Button, Tooltip } from "antd";

import Moment from "moment";
import { extendMoment } from "moment-range";

import { NOVARC_PRODUCT } from "../../libs/constants";
import AlarmListCard from "./AlarmListCard";
import StatsChartCard from "./StatsChartCard";

const moment = extendMoment(Moment);

const activeButtonStyle = {
  color: "#40a9ff",
  borderColor: "#40a9ff",
};

function RobotAlarmStatistics(props) {
  const data = props.data ?? [];
  const [dateSelectKey, setDateSelectKey] = useState(2);

  const range = moment.rangeFromInterval("day", -30, moment());
  // note - calling moment() or date in global scope means it executes before clock override in component tests
  const filterData = [
    {
      key: 1,
      title: "Last 24 hours",
      value: 1,
      date: moment().subtract(1, "days"),
    },
    {
      key: 2,
      title: "Last 7 days",
      value: 7,
      date: moment().subtract(7, "days"),
    },
    {
      key: 3,
      title: "Last 30 days",
      value: 30,
      date: moment().subtract(30, "days"),
    },
  ];
  const filterDataBy = (type, from) =>
    Array.from(range.by("day"))
      .map((day) => {
        if (day.isAfter(from)) {
          return {
            date: day.format("YYYY-MM-DD"),
            value: data.filter(
              (item) =>
                day.format("YYYY-MM-DD") ===
                  moment(item.received_at).format("YYYY-MM-DD") &&
                item.type === type
            ).length,
            type: type,
          };
        }
        return null;
      })
      .filter((item) => item);

  const trendsData = [
    ...filterDataBy(
      "warning",
      filterData
        .find(({ key }) => key === dateSelectKey)
        .date.format("YYYY-MM-DD")
    ),
    ...filterDataBy(
      "error",
      filterData
        .find(({ key }) => key === dateSelectKey)
        .date.format("YYYY-MM-DD")
    ),
  ];

  const columns = [
    {
      title: "Type",
      width: "5%",
      // dataIndex: "unit",
      filters: [
        { text: "Error", value: "error" },
        { text: "Warning", value: "warning" },
      ],
      onFilter: (value, record) => record.type.indexOf(value) === 0,

      render: (text, item, index) => {
        return {
          children: (
            <Tooltip placement="topLeft" title={item.detail}>
              <Alert
                className="AlarmStatistics_Alert"
                style={{ margin: "-5px auto" }}
                type={item.type === "warning" ? "warning" : "error"}
                showIcon
                message={
                  <span>
                    <span>{item.message}</span> <i>{item.received_at}</i>
                  </span>
                }
              ></Alert>
            </Tooltip>
          ),
          //todo unlock this
          props: { colSpan: 3 },
        };
      },
    },
    {
      title: "Message",
      sorter: {
        compare: (a, b) => {
          const aString = a.message.toLowerCase();
          const bString = b.message.toLowerCase();

          if (aString > bString) {
            return 1;
          }
          if (aString < bString) {
            return -1;
          }
          return 0;
        },
      },
      // dataIndex: "message",
    },
    {
      title: "Time",
      sorter: {
        compare: (a, b) => {
          const aDate = new Date(a.received_at);
          const bDate = new Date(b.received_at);

          if (aDate > bDate) {
            return 1;
          }
          if (aDate < bDate) {
            return -1;
          }
          return 0;
        },
        multiple: 1,
      },
      // dataIndex: "received_at",
    },
  ];

  const pagination = { hideOnSinglePage: true };

  return (
    <div className="alarmstatistics">
      <div className="header">
        <h1>{NOVARC_PRODUCT} Health</h1>
        <div className="date-select-buttons">
          <Button
            onClick={() => {
              // setDateValues([filterData[0].date, today]);
              setDateSelectKey(1);
            }}
            style={dateSelectKey === 1 ? activeButtonStyle : {}}
          >
            {filterData.find((item) => item.key === 1).title}
          </Button>
          <Button
            onClick={() => {
              // setDateValues([filterData[1].date, today]);
              setDateSelectKey(2);
            }}
            style={dateSelectKey === 2 ? activeButtonStyle : {}}
          >
            {filterData.find((item) => item.key === 2).title}
          </Button>
          <Button
            onClick={() => {
              // setDateValues([filterData[2].date, today]);
              setDateSelectKey(3);
            }}
            style={dateSelectKey === 3 ? activeButtonStyle : {}}
          >
            {filterData.find((item) => item.key === 3).title}
          </Button>
        </div>
      </div>
      <StatsChartCard trendsData={trendsData} />
      <AlarmListCard
        columns={columns}
        data={data}
        filterData={filterData}
        dateSelectKey={dateSelectKey}
        pagination={pagination}
      />
    </div>
  );
}

export default RobotAlarmStatistics;
