import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { useParams, useLocation } from "react-router-dom";

import { push } from "connected-react-router";

import { Breadcrumb, Menu } from "antd";
import {
  AlertOutlined,
  FileExcelOutlined,
  FilePdfOutlined,
  FileSearchOutlined,
  FileTextOutlined,
  FundProjectionScreenOutlined,
  PlaySquareOutlined,
  ProfileOutlined,
  RobotOutlined,
  BarChartOutlined,
  SnippetsOutlined,
} from "@ant-design/icons";
import { isNovAI } from "../../libs/constants";
import { normalizeRobotName } from "../../libs/utils";

function RobotNavigation(props) {
  const { isCustomer } = props;
  const { robotName } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();

  const robotList = useSelector((state) => state.robot.index);

  if (!robotList.length) {
    return null;
  }

  const currentPath = location.pathname.split("/").slice(-1)[0];

  const nameFromPath = (
    location.hash
      ? location.hash.split("/")[0].replace("#", "").replace(/_/g, " ")
      : location.pathname.split("/").slice(-1)[0].replace(/_/g, " ")
  ).toLowerCase();

  // TODO: This would be unified later once full features are available
  if (isNovAI()) {
    return (
      <Breadcrumb style={{ paddingLeft: 8, paddingTop: 5, paddingBottom: 13 }}>
        <Breadcrumb.Item
          overlay={
            <Menu>
              {isCustomer ? (
                <>
                  <Menu.Item
                    key={`/${robotName}/videos`}
                    icon={<FileSearchOutlined />}
                    onClick={() => {
                      window.scrollTo(0, 0);
                      dispatch(
                        push(`/${robotName}/search#videos_&_daily_reports`)
                      );
                    }}
                  >
                    Videos & Daily Reports
                  </Menu.Item>
                </>
              ) : (
                // currently admin side gets 2 dropdowns, robot and page
                robotList.map((id, index) => (
                  <Menu.Item
                    key={index}
                    icon={<RobotOutlined />}
                    onClick={() => {
                      dispatch(
                        push(`/${normalizeRobotName(id)}/${currentPath}`)
                      );
                    }}
                  >
                    {normalizeRobotName(id)}
                  </Menu.Item>
                ))
              )}
            </Menu>
          }
        >
          <span
            style={{
              color: "#001529",
              fontWeight: 400,
            }}
          >
            {robotName}
          </span>
        </Breadcrumb.Item>

        <Breadcrumb.Item
          overlay={
            !isCustomer && (
              <Menu>
                <>
                  <Menu.Item
                    key={`/${robotName}/search`}
                    icon={<FileSearchOutlined />}
                    onClick={() => {
                      window.scrollTo(0, 0);
                      dispatch(push(`/${robotName}/search`));
                    }}
                  >
                    Videos & Daily Reports
                  </Menu.Item>
                </>
              </Menu>
            )
          }
        >
          <span
            style={{
              textTransform: "capitalize",
              fontWeight: 600,
              fontSize: 18,
            }}
          >
            {nameFromPath === "weld test results"
              ? "Weld QC Report"
              : nameFromPath === "search"
              ? "Videos & Daily Reports"
              : nameFromPath}
          </span>
        </Breadcrumb.Item>
      </Breadcrumb>
    );
  }

  return (
    <Breadcrumb style={{ paddingLeft: 8, paddingTop: 5, paddingBottom: 13 }}>
      <Breadcrumb.Item
        overlay={
          <Menu>
            {isCustomer ? (
              <>
                <Menu.Item
                  key={`/${robotName}/analytics`}
                  icon={<BarChartOutlined />}
                  onClick={() => {
                    window.scrollTo(0, 0);
                    dispatch(push(`/${robotName}/analytics`));
                  }}
                >
                  Analytics
                </Menu.Item>
                <Menu.Item
                  key={`/${robotName}/health`}
                  icon={<AlertOutlined />}
                  onClick={() => {
                    window.scrollTo(0, 0);
                    dispatch(push(`/${robotName}/health`));
                  }}
                >
                  Health
                </Menu.Item>
                <Menu.Item
                  key={`/${robotName}/videos`}
                  icon={<FileSearchOutlined />}
                  onClick={() => {
                    window.scrollTo(0, 0);
                    dispatch(
                      push(`/${robotName}/search#videos_&_daily_reports`)
                    );
                  }}
                >
                  Videos & Daily Reports
                </Menu.Item>
                <Menu.Item
                  key={`/${robotName}/weld_test_results`}
                  icon={<SnippetsOutlined />}
                  onClick={() => {
                    window.scrollTo(0, 0);
                    dispatch(push(`/${robotName}/weld_test_results`));
                  }}
                >
                  Weld QC Report
                </Menu.Item>
                <Menu.Item
                  key={`/${robotName}/support`}
                  icon={
                    // placeholder
                    <SnippetsOutlined />
                  }
                  onClick={() => {
                    window.scrollTo(0, 0);
                    dispatch(push(`/${robotName}/support`));
                  }}
                >
                  Support
                </Menu.Item>
              </>
            ) : (
              // currently admin side gets 2 dropdowns, robot and page
              robotList.map((id, index) => (
                <Menu.Item
                  key={index}
                  icon={<RobotOutlined />}
                  onClick={() => {
                    dispatch(push(`/${normalizeRobotName(id)}/${currentPath}`));
                  }}
                >
                  {normalizeRobotName(id)}
                </Menu.Item>
              ))
            )}
          </Menu>
        }
      >
        <span
          style={{
            color: "#001529",
            fontWeight: 400,
          }}
        >
          {robotName}
        </span>
      </Breadcrumb.Item>

      <Breadcrumb.Item
        overlay={
          !isCustomer && (
            <Menu>
              <>
                <Menu.Item
                  key={`/${robotName}/analytics`}
                  icon={<ProfileOutlined />}
                  onClick={() => {
                    window.scrollTo(0, 0);
                    dispatch(push(`/${robotName}/analytics`));
                  }}
                >
                  Analytics
                </Menu.Item>
                <Menu.Item
                  key={`/${robotName}/health`}
                  icon={<ProfileOutlined />}
                  onClick={() => {
                    window.scrollTo(0, 0);
                    dispatch(push(`/${robotName}/health`));
                  }}
                >
                  Health
                </Menu.Item>
                <Menu.Item
                  key={`/${robotName}/search`}
                  icon={<FileSearchOutlined />}
                  onClick={() => {
                    window.scrollTo(0, 0);
                    dispatch(push(`/${robotName}/search`));
                  }}
                >
                  Videos & Daily Reports
                </Menu.Item>
                <Menu.Item
                  key={`/${robotName}/weld_test_results`}
                  icon={<SnippetsOutlined />}
                  onClick={() => {
                    window.scrollTo(0, 0);
                    dispatch(push(`/${robotName}/weld_test_results`));
                  }}
                >
                  Weld QC Report
                </Menu.Item>
                <Menu.Item
                  key={`/${robotName}/videos`}
                  icon={<PlaySquareOutlined />}
                  onClick={() => {
                    window.scrollTo(0, 0);
                    dispatch(push(`/${robotName}/videos`));
                  }}
                >
                  Search Video
                </Menu.Item>
                <Menu.Item
                  key={`/${robotName}/reports`}
                  icon={<FilePdfOutlined />}
                  onClick={() => {
                    window.scrollTo(0, 0);
                    dispatch(push(`/${robotName}/reports`));
                  }}
                >
                  Search Daily Report
                </Menu.Item>
                <Menu.Item
                  key={`/${robotName}/novdata_logs`}
                  icon={<FileExcelOutlined />}
                  onClick={() => {
                    window.scrollTo(0, 0);
                    dispatch(push(`/${robotName}/novdata_logs`));
                  }}
                >
                  Search NovData Log
                </Menu.Item>
                <Menu.Item
                  key={`/${robotName}/noveye_logs`}
                  icon={<FileTextOutlined />}
                  onClick={() => {
                    window.scrollTo(0, 0);
                    dispatch(push(`/${robotName}/noveye_logs`));
                  }}
                >
                  Search NovEye Log
                </Menu.Item>
                <Menu.Item
                  key={`/${robotName}/alarm_list`}
                  icon={<AlertOutlined />}
                  onClick={() => {
                    window.scrollTo(0, 0);
                    dispatch(push(`/${robotName}/alarm_list`));
                  }}
                >
                  Alarm List
                </Menu.Item>
                <Menu.Item
                  key={`/${robotName}/statistics`}
                  icon={<FundProjectionScreenOutlined />}
                  onClick={() => {
                    window.scrollTo(0, 0);
                    dispatch(push(`/${robotName}/statistics`));
                  }}
                >
                  Statistics
                </Menu.Item>
              </>
            </Menu>
          )
        }
      >
        <span
          style={{ textTransform: "capitalize", fontWeight: 600, fontSize: 18 }}
        >
          {nameFromPath === "weld test results"
            ? "Weld QC Report"
            : nameFromPath === "search"
            ? "Videos & Daily Reports"
            : nameFromPath}
        </span>
      </Breadcrumb.Item>
    </Breadcrumb>
  );
}

export default RobotNavigation;
