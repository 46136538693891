import { Button, Table, Tooltip } from "antd";
import { PreviewIcon } from "../../components/icons/NovarcIcons";
import { ExclamationCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import moment from "moment";
import {
  isNovAI,
  NOVDATA_SPECIAL_CHAR_REGEX,
  NOVEYE_SPECIAL_CHAR_REGEX,
  NOVEYE_VIDEO_TIMESTAMP_REGEX,
} from "../../libs/constants";
import { getNovDataLogLink, getVideoLink } from "./robotSearchUtils";
import type { S3File } from "./robotSearchUtils";
import { useSelector } from "react-redux";
import { WeldRunWithFiles } from "./robotSlice";
import SubmitWeldFileChange from "./SubmitWeldFileChange";

export function RunNumDiagnostics(props) {
  const {
    videos,
    isLimitExceeded,
    isCustomer,
    TOOLTIP_MSG,
    handleModalPreviewOpen,
    DownloadButton,
    robotId,
    handleUsageUpdate,
    showTimeOut,
    TOOLTIP_WARNING,
    currentWeldData,
    novdata_logs,
    showViews,
  } = props;
  // @ts-ignore
  const robotWeldFiles = useSelector((state) => state.robot.robotFiles?.[robotId]);
  //for a given currentWeldData job/spool will not differ for entire list
  const work_order_ref = currentWeldData?.[0]?.work_order_ref;
  const spool_ref = currentWeldData?.[0]?.spool_ref;
  const diagnosticsByWeldRef = (
    [...new Set(currentWeldData.map((e) => e.welder_id_ref))] as string[]
  ).map((welder_id_ref, index) => {
    const currentWeldRuns = currentWeldData.filter(
      (weld) => weld.welder_id_ref === welder_id_ref
    );
    const vidFiles = findFilesByJobSpoolWeld(
      videos,
      {
        work_order_ref,
        spool_ref,
        welder_id_ref,
      },
      "Video"
    );
    const logFiles = findFilesByJobSpoolWeld(
      novdata_logs,
      {
        work_order_ref,
        spool_ref,
        welder_id_ref,
      },
      "Log"
    );

    const vidStatus = matchingCheck(currentWeldRuns, vidFiles, "Video");
    const logStatus = matchingCheck(currentWeldRuns, logFiles, "Log");

    return {
      weldRef: welder_id_ref,
      numWelds: currentWeldRuns.length,
      numVidFiles: vidFiles.length,
      numLogFiles: logFiles.length,
      vidStatus: vidStatus ? "OK ✅" : "Error ❌",
      logStatus: logStatus ? "OK ✅" : "Error ❌",
      key: index,
    };
  });
  return (
    /* eslint-disable */
    <Table
      columns={[
        {
          title: "Weld Ref",
          key: "weld_info",
          dataIndex: "weldRef",
        },
        {
          title: "Vid Status",
          key: "vid_status_msg",
          dataIndex: "vidStatus",
        },
        {
          title: "Log Status",
          key: "log_status_msg",
          dataIndex: "logStatus",
        },
        {
          title: "Num of welds",
          key: "number_of_welds",
          dataIndex: "numWelds",
        },
        {
          title: "Num video files found",
          key: "number_of_videos",
          dataIndex: "numVidFiles",
        },
        {
          title: "Num log files found",
          key: "number_of_logs",
          dataIndex: "numLogFiles",
        },
      ]}
      dataSource={diagnosticsByWeldRef}
      expandable={{
        expandedRowRender: (row) => (
          // possible bug: when clicking button all rows expand at once
          <SideBySide weldRef={row.weldRef} showViews={showViews} />
        ),
      }}
    />
  );

  function SideBySide(props) {
    const { weldRef, showViews } = props;
    const showWelds = showViews.includes("Show Welds");
    const showVids = showViews.includes("Show Video Files");
    const showLogs = showViews.includes("Show Log Files");
    // showReports does not contribute to table width
    const numCols = showWelds + showVids + showLogs;
    return (
      <div
        style={{
          display: "grid",
          width: "100%",
          height: "100%",
          gridTemplateColumns: `repeat(${numCols},1fr)`,
          gridGap: 10,
        }}
      >
        {numCols === 0 && <p>No views selected! Use checkboxes to show</p>}
        {showWelds && <WeldTable />}
        {showVids && <FileListTable weldRef={weldRef} type={"Video"} />}
        {showLogs && <FileListTable weldRef={weldRef} type={"Log"} />}
      </div>
    );
  }
  function WeldTable() {
    const [modalEditOpen, setModalEditOpen] = useState(false);
    const [editEntry, setEditEntry] = useState(null);

    return (
      <>
        <Table
          title={() => <h1>Welds (customer view)</h1>}
          columns={[
            {
              title: "Start Time",
              key: "arc_on_utc",
              render: (_, item) => {
                return (
                  <span>
                    {moment(item.arc_on_utc).format("MMM DD HH:mm:ss")}
                  </span>
                );
              },
            },
            {
              title: "End Time",
              key: "arc_off_utc",
              render: (_, item) => {
                return (
                  <span>{moment(item.arc_off_utc).format("HH:mm:ss")}</span>
                );
              },
            },
            !isNovAI() && {
              title: "Run",
              key: "run_number",
              dataIndex: "run_number",
              sorter: (a, b) => a.run_number - b.run_number,
            },
            // {

            //   title: "Run",
            //   key: "run_number",
            //   dataIndex: "run_number",
            //   sorter: (a, b) => a.run_number - b.run_number,
            // },
            {
              title: "Videos",
              key: "videos",
              render: (_, { video,tsv }) => {
                return video ? (
                  <span style={{ fontSize: 24 }}>
                    <Tooltip
                      title={
                        isLimitExceeded && isCustomer
                          ? TOOLTIP_MSG
                          : "Click to preview"
                      }
                    >
                      <PreviewIcon
                        style={{
                          color: "#1890ff",
                          cursor:
                            isLimitExceeded && isCustomer
                              ? "not-allowed"
                              : "pointer",
                        }}
                        onClick={() =>
                          isLimitExceeded && isCustomer
                            ? null
                            : handleModalPreviewOpen(video)
                        }
                      />
                    </Tooltip>{" "}
                    <DownloadButton
                      isLimitExceeded={isLimitExceeded && isCustomer}
                      robotId={robotId}
                      item={video}
                      handleUsageUpdate={handleUsageUpdate}
                      className="download-button-big"
                    />
                  </span>
                ) : showTimeOut ? (
                  <Tooltip title={TOOLTIP_WARNING}>
                    <ExclamationCircleOutlined />
                  </Tooltip>
                ) : (
                  <LoadingOutlined />
                );
              },
            },
            {
              title: "Logs",
              key: "logs",
              // dataIndex: "",
              render: (_, { video, tsv }) => {
                return tsv ? (
                  <span style={{ fontSize: 24 }}>
                    <DownloadButton
                      isLimitExceeded={isLimitExceeded && isCustomer}
                      robotId={robotId}
                      item={tsv}
                      handleUsageUpdate={handleUsageUpdate}
                      className="download-button-big"
                    />
                  </span>
                ) : showTimeOut ? (
                  <Tooltip title={TOOLTIP_WARNING}>
                    <ExclamationCircleOutlined />
                  </Tooltip>
                ) : (
                  <LoadingOutlined />
                );
              },
            },
            {
              title: "Edit",
              key: "edit",
              render: (_, weld) => (
                <Button
                  onClick={() => {
                    setModalEditOpen(true);
                    setEditEntry(
                      robotWeldFiles?.find?.(
                        (e) => e?.weld_files_id === weld?.weld_files_id
                      )
                    );
                  }}
                >
                  Edit
                </Button>
              ),
            },
          ]}
          dataSource={currentWeldData.map((weld) => {
            const video = getVideoLink(videos, weld);
            const tsv = getNovDataLogLink(novdata_logs, weld);

            return { ...weld, video, tsv };
          })}
        />
        <SubmitWeldFileChange
          open={modalEditOpen}
          close={() => setModalEditOpen(false)}
          entry={editEntry}
          logList={(novdata_logs as S3File[]) ?? []}
          videoList={(videos as S3File[]) ?? []}
        />
      </>
    );
  }
  function FileListTable(props) {
    const { weldRef, type } = props;
    const dataSource =
      type === "Video" ? videos : type === "Log" ? novdata_logs : null;
    // must be rendered with a valid type
    if (dataSource === null) return <Table />;
    const relatedFiles = findFilesByJobSpoolWeld(
      dataSource,
      {
        //for a given currentWeldData job/spool will not differ for entire list
        work_order_ref: currentWeldData?.[0]?.work_order_ref,
        spool_ref: currentWeldData?.[0]?.spool_ref,
        welder_id_ref: weldRef,
      },
      type
    ).sort(
      (a, b) =>
        // @ts-ignore
        new Date(b.file.uploadedAt) - new Date(a.file.uploadedAt)
    );

    return (
      <Table
        title={() => <h1>{type} Files found</h1>}
        columns={[
          {
            title: "Upload Time",
            key: "uploaded_at",
            dataIndex: "uploadedAt",
            // Date obj does not parse this cross platform
            // sorter: (a, b) => new Date(a.uploadedAt) - new Date(b.uploadedAt),
          },
          !isNovAI() && {
            title: "Run",
            key: "run_num",
            dataIndex: "runNum",
            sorter: (a, b) => a.runNum - b.runNum,
          },
          {
            title: `${type} File`,
            key: "file",
            sorter: (a, b) => a.file.size - b.file.size,
            render: (_, { file }) => (
              <span style={{ fontSize: 24 }}>
                <DownloadButton
                  isLimitExceeded={isLimitExceeded && isCustomer}
                  robotId={robotId}
                  item={file}
                  handleUsageUpdate={handleUsageUpdate}
                  className="download-button-big"
                />
              </span>
            ),
          },
        ].filter((i) => i)}
        dataSource={relatedFiles.map((e) => ({
          runNum: e.r,
          file: e.file,
          uploadedAt: moment(e.file.uploadedAt).format("MMM DD HH:mm:ss"),
        }))}
      />
    );
  }
}

function matchingCheck(
  currentWeldRuns: WeldRunWithFiles[],
  files: PreprocessedFile[],
  type: "Video" | "Log"
) {
  const forwardTest = currentWeldRuns.reduce(
    (errCount, curr) =>
      files.find((e) => e.r === curr.run_number) ? errCount : errCount + 1,
    0
  );

  const reverseTest = files.reduce(
    (errCount, curr) =>
      currentWeldRuns.find((e) => e.run_number === curr.r)
        ? errCount
        : errCount + 1,
    0
  );

  if (forwardTest > 0) {
    console.warn(`${forwardTest} welds without corresponding ${type}`);
  }
  if (reverseTest > 0) {
    console.warn(`${reverseTest} ${type}s without corresponding weld`);
  }
  return forwardTest === 0 && reverseTest === 0;
}
interface PreprocessedFile {
  j: string;
  s: string;
  w: string;
  r: number;
  file: S3File;
}

export function findFilesByJobSpoolWeld(
  fileList: S3File[],
  weldFromRds: Partial<WeldRunWithFiles>,
  type: "Video" | "Log"
): PreprocessedFile[] {
  const {
    work_order_ref = "",
    spool_ref = "",
    welder_id_ref = "",
  } = weldFromRds;

  if (!fileList || !weldFromRds) {
    return [];
  }

  //only noveye removes spaces in file names, so this needs to be handled for video files
  if (type === "Video") {
    const j = work_order_ref.replace(NOVEYE_SPECIAL_CHAR_REGEX, "");
    const s = spool_ref.replace(NOVEYE_SPECIAL_CHAR_REGEX, "");
    const w = welder_id_ref.replace(NOVEYE_SPECIAL_CHAR_REGEX, "");
    const fileNameFromRds = `${j}_${s}_${w}`;

    return fileList
      .filter(
        (file) =>
          (file.fileName.startsWith("weld_vision." + fileNameFromRds + "-") ||
            file.fileName.startsWith("NovEye." + fileNameFromRds + "-")) &&
          file.fileName.match(/-(\d+)\.mp4$/)?.length > 1
      )
      .map((file) => {
        const runNumMatcher = file.fileName.match(/-(\d+)\.mp4$/);
        const runNum = Number(runNumMatcher[1]);
        return {
          j: j,
          s: s,
          w: w,
          r: runNum,
          file: file,
        };
      });
  } else {
    const j = work_order_ref.replace(NOVDATA_SPECIAL_CHAR_REGEX, "");
    const s = spool_ref.replace(NOVDATA_SPECIAL_CHAR_REGEX, "");
    const w = welder_id_ref.replace(NOVDATA_SPECIAL_CHAR_REGEX, "");
    const fileNameFromRds = `${j}_${s}_${w}`;

    return fileList
      .filter(
        (file) =>
          (file.fileName.startsWith("NovData-Log-" + fileNameFromRds + "_") ||
            file.fileName.startsWith("log_" + fileNameFromRds + "_")) &&
          file.fileName.match(/_(\d+)\.tsv$/)?.length > 1
      )
      .map((file) => {
        const runNumMatcher = file.fileName.match(/_(\d+)\.tsv$/);
        const runNum = Number(runNumMatcher[1]);
        return {
          j: j,
          s: s,
          w: w,
          r: runNum,
          file: file,
        };
      });
  }
}
export function getTimestampsFromVideoList(filesFromS3) {
  if (!filesFromS3?.length) return [];
  return filesFromS3
    .map((file) => ({
      file,
      match: file.fileName
        .replace(/ /g, "")
        .match(NOVEYE_VIDEO_TIMESTAMP_REGEX),
    }))
    .filter(({ file, match }) => match)
    .map(({ file, match }) => ({
      file,
      date: match[1],
      time: match[2],
    }));
}

export function findVideosByTimestamp(timestampVideoList, weldFromRds) {
  const { arc_on_utc = "", arc_off_utc = "" } = weldFromRds;
  const date = arc_on_utc.substring(0, 10);
  return timestampVideoList.filter((e) => e.date === date);
}
