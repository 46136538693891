import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Alert, Form, Input, Button, Checkbox } from "antd";
import { MailOutlined, LockOutlined } from "@ant-design/icons";

import logo from "../../assets/novarc-logo.black.svg";

import { loginUser } from "./authSlice";
import { NOVARC_PRODUCT, ROUTER_AUTH_PASSWORD_RECOVERY } from "../../libs/constants";

const SignIn = (props) => {
  const loggingIn = useSelector((state) => state.auth.loggingIn);
  const loggingInError = useSelector((state) => state.auth.loggingInError);

  const dispatch = useDispatch();

  const handleSubmit = ({ email, password }) => {
    // console.log("handleSubmit", email, password);
    dispatch(loginUser(email, password));
  };

  const onClose = (e) => {
    // console.log(e, "Closing");
  };

  return (
    <div
      style={{
        textAlign: "center",
        verticalAlign: "middle",
        maxWidth: 400,
        top: "50%",
        left: "50%",
        position: "absolute",
        MsTransform: "translate(-50%, -50%)",
        transform: "translate(-50%, -50%)",
      }}
    >
      <img
        className="logo"
        alt="logo"
        src={logo}
        style={{ height: 80, margin: "12px" }}
      />

      <p>Novarc NovSync Dashboard for {NOVARC_PRODUCT}</p>

      <Form
        name="login"
        className="login-form"
        initialValues={{ remember: true }}
        onFinish={handleSubmit}
        style={{ marginTop: 40 }}
      >
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: "Please input your Email!",
            },
            {
              type: "email",
              message: "Invalid Email Format!",
            },
          ]}
        >
          <Input prefix={<MailOutlined />} placeholder="Email" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: "Please input your Password!" }]}
        >
          <Input
            prefix={<LockOutlined />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item style={{ textAlign: "left" }}>
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox>Remember me</Checkbox>
          </Form.Item>
          <Link to={ROUTER_AUTH_PASSWORD_RECOVERY} style={{ float: "right" }}>
            Forgot password
          </Link>
        </Form.Item>

        {/* {!loggingIn ? <LoadingPage></LoadingPage> : null} */}

        {!!loggingInError && (
          <React.Fragment>
            <Alert
              style={{ fontSize: "0.8em" }}
              message={
                !!loggingInError &&
                "Wrong password. Try again or click Forgot password to reset it."
              }
              type="error"
              closable
              onClose={onClose}
            />
            <br />
          </React.Fragment>
        )}

        <Form.Item>
          <Button block type="primary" htmlType="submit" loading={loggingIn}>
            Sign in
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default SignIn;
