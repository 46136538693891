import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Button, Empty, Space, Select } from "antd";

import { Bar } from "@ant-design/charts";

import moment from "moment";
import { chain } from "lodash";

import { ReloadOutlined } from "@ant-design/icons";

import { getTopOperatorsWeldHoursList } from "../../features/performance/performanceSlice";

import PickerWithType from "../../libs/components/PickerWithType";
import SelectDateType from "../../libs/components/SelectDateType";
import { NOVARC_PRODUCT } from "../../libs/constants";
import { GetDateRange, normalizeRobotName } from "../../libs/utils";

const today = moment();
const lastWeek = moment().subtract(7, "days");

function TopOperatorsWeldHours(props) {
  const dispatch = useDispatch();

  const top_operators_weld_hours =
    useSelector((state) => state.performance.top_operators_weld_hours) || [];

  const [dateValues, setDateValues] = useState(null);
  const [selectedValues, setSelectedValues] = useState(null);
  const [type, setType] = useState("range");

  useEffect(() => {
    dispatch(
      getTopOperatorsWeldHoursList(null, {
        start: lastWeek.format("YYYY-MM-DD"),
        end: today.format("YYYY-MM-DD"),
      })
    );
  }, [dispatch]);

  const weldHours = [...top_operators_weld_hours].map((item) => ({
    ...item,
    // robot_id uses the name instead of internal id
    // does it use this for display or only for grouping?
    // does it need to be a number?
    robot_id: Number(normalizeRobotName(item.robot_id, false)),
    weld_time: moment.duration(item.weld_hours).asHours(),
  }));

  const totalWeldHours = chain([...weldHours])
    .groupBy("robot_id")
    .entries()
    .map(([key, value]) => {
      return {
        robot_id: key,
        value: value.slice(1).reduce((prev, curr) => {
          return prev.add({ hours: curr.weld_time });
        }, moment.duration({ hours: value[0].weld_time }))._data,
      };
    })
    .value();

  return (
    <>
      <Space>
        <b>Top Operators Weld Hours</b>

        <SelectDateType value={type} onChange={setType}></SelectDateType>
        <PickerWithType
          type={type}
          defaultValue={[lastWeek, today]}
          onChange={(value) => setDateValues(value)}
        />
        <Button
          disabled={!dateValues}
          onClick={() => {
            const dateRange = GetDateRange(type, dateValues);

            dispatch(
              getTopOperatorsWeldHoursList(null, {
                start: dateRange[0],
                end: dateRange[1],
              })
            );
          }}
          icon={<ReloadOutlined />}
        ></Button>

        <Select
          style={{ minWidth: 200 }}
          mode="multiple"
          placeholder={`Filter by ${NOVARC_PRODUCT} number`}
          options={weldHours
            .map((item) => item.robot_id)
            .filter((v, i, a) => a.indexOf(v) === i)
            .map((item) => ({
              label: `SWR-${item}`,
              value: item,
            }))}
          onChange={(values) => {
            setSelectedValues(values);
          }}
        ></Select>
      </Space>

      <div>
        {top_operators_weld_hours && top_operators_weld_hours.length > 0 ? (
          <Bar
            {...{
              data:
                !selectedValues || selectedValues.length === 0
                  ? weldHours
                  : weldHours.filter((v, i, a) => {
                      return selectedValues.includes(v.robot_id);
                    }),
              xField: "weld_time",
              yField: "robot_id",
              // color: "#1890ff",
              barWidthRatio: 0.9,
              maxBarWidth: 20,
              legend: false,
              isStack: true,
              seriesField: "operator_name",
              label: {
                position: "middle",
                formatter: function (v) {
                  return v.weld_time.toFixed(1);
                },
              },
              xAxis: {
                label: {
                  formatter: function formatter(v) {
                    return moment.duration({ hours: v }).asHours().toFixed(1);
                  },
                },
              },
              yAxis: {
                label: {
                  formatter: function formatter(v) {
                    return `${NOVARC_PRODUCT}-${v}`;
                  },
                },
              },

              tooltip: {
                title: (v) => {
                  const total = moment
                    .duration(
                      totalWeldHours.find((item) => item.robot_id === v).value
                    )
                    .asHours()
                    .toFixed(1);
                  return `${NOVARC_PRODUCT}-${v}: ${total} hours`;
                },
                fields: ["operator_name", "robot_id", "weld_time"],
                formatter: (datum) => {
                  return {
                    name: datum.operator_name,
                    value:
                      datum.weld_time &&
                      moment
                        .duration({ hours: datum.weld_time })
                        .asHours()
                        .toFixed(1) + " hours",
                  };
                },
              },
            }}
          />
        ) : (
          <Empty />
        )}
      </div>
    </>
  );
}
export default TopOperatorsWeldHours;
