import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { Card, Input, Skeleton } from "antd";

import { getRobotGeolocation, getRobotsList } from "../robot/robotSlice";
import LoadingPage from "../../components/LoadingPage";

import { LOADING_ANIMATION_SIZE } from "../../libs/constants";
import { useLoadingWithProgress } from "../../libs/useLoading";
import { WorldMap } from "./WorldMap";
import { DistributionChart } from "./DistributionChart";

const { Search } = Input;

const LOADING_DELAY_IN_MS = 1000;

function selectMap(type) {
  switch (type) {
    case "United States":
      return {
        zoom: 5,
        center: [36.2115201, -113.7164387],
      };

    case "Canada":
      return {
        zoom: 5,
        center: [-15, 24],
      };
    case "Europe":
      return {
        zoom: 5,
        center: [24.1474343, 48.0988048],
      };
    case "Asia":
      return {
        zoom: 5,
        center: [70, 23.6906011],
      };
    default:
      return {
        zoom: 2.4,
        center: [33.3861128, -3.1831302],
      };
  }
}

export default function GeolocationMap(props) {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const [[robots, robotList, geoData], progress] = useLoadingWithProgress(
    "robot",
    ({ robots, index, geo }) => [robots, index, geo],
    // dispatch for getRobots is fired in sibling component (sidebar)
    [getRobotsList, getRobotGeolocation]
  );

  const [loadingDelay, setLoadingDelay] = useState(false);

  useEffect(() => {
    dispatch(getRobotGeolocation());
  }, [dispatch]);

  useEffect(() => {
    if (!loadingDelay) return () => {};
    const id = setTimeout(() => {
      setLoadingDelay(false);
    }, LOADING_DELAY_IN_MS);
    return () => clearTimeout(id);
  }, [loadingDelay]);

  // Weak loading guard here that lets the geo map load independently from the main dashboard page
  // Note that the Full loading page is not used because this component is not full screen
  if (!progress.isFinished()) {
    return <LoadingPage size={LOADING_ANIMATION_SIZE} />;
  }

  function filterSWR() {
    if (searchText === "") {
      return robotList;
    }
    const searchLower = searchText.toLowerCase();
    return robotList.filter((id) => {
      const robot = robots[id];
      return (
        String(robot.name).toLowerCase().includes(searchLower) ||
        String(robot.customer).toLowerCase().includes(searchLower) ||
        String(robot.company).toLowerCase().includes(searchLower) ||
        String(robot.location).toLowerCase().includes(searchLower)
      );
    });
  }

  return (
    <Card
      // what exactly needs to be passed down as prop?
      {...props}
      extra={
        <Search
          placeholder="Search SWR"
          onSearch={() => {
            // search button effectively does nothing because it instantly updates on change
            setLoadingDelay(Date.now());
          }}
          onChange={(e) => {
            setSearchText(e.target.value);
            setLoadingDelay(Date.now());
          }}
          enterButton
        />
      }
    >
      <Card.Grid
        hoverable={false}
        style={{ padding: 12, width: "75%", height: "34.5vh" }}
      >
        <WorldMap robotLocations={geoData}></WorldMap>
      </Card.Grid>
      <Card.Grid
        hoverable={false}
        style={{ padding: 12, width: "25%", height: "34.5vh" }}
      >
        {!loadingDelay ? (
          <DistributionChart geoData={geoData} />
        ) : (
          <Skeleton.Node
            active={true}
            style={{ height: 350, width: "100%", background: "white" }}
          >
            <LoadingPage size={LOADING_ANIMATION_SIZE} />
          </Skeleton.Node>
        )}
      </Card.Grid>
    </Card>
  );
}
