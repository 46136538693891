import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Button, Empty, Space, Select } from "antd";

import { Bar } from "@ant-design/charts";

import moment from "moment";
import { chain, sumBy } from "lodash";
import { ReloadOutlined } from "@ant-design/icons";

import { getTopWeldInchesList } from "../../features/performance/performanceSlice";

import PickerWithType from "../../libs/components/PickerWithType";
import SelectDateType from "../../libs/components/SelectDateType";
import { NOVARC_PRODUCT } from "../../libs/constants";
import { GetDateRange, normalizeRobotName } from "../../libs/utils";

const today = moment();
const lastWeek = moment().subtract(7, "days");

function TopWeldInches(props) {
  const dispatch = useDispatch();
  const top_weld_inches =
    useSelector((state) => state.performance.top_weld_inches) || [];

  const [dateValues, setDateValues] = useState(null);
  const [selectedValues, setSelectedValues] = useState(null);
  const [type, setType] = useState("range");

  useEffect(() => {
    dispatch(
      getTopWeldInchesList(null, {
        start: lastWeek.format("YYYY-MM-DD"),
        end: today.format("YYYY-MM-DD"),
      })
    );
  }, [dispatch]);

  const data =
    !!top_weld_inches && top_weld_inches.length > 0
      ? chain(top_weld_inches)
          .groupBy("id")
          .map((value, index) => ({
            id: index,
            value: sumBy(value, "value"),
          }))
          .filter((item) => item.value > 0)
          .value()
          .sort((a, b) => b.value - a.value)
      : [];

  return (
    <>
      <Space>
        <b>Top Weld Inches (FDI)</b>

        <SelectDateType value={type} onChange={setType}></SelectDateType>
        <PickerWithType
          type={type}
          defaultValue={[lastWeek, today]}
          onChange={(value) => setDateValues(value)}
        />
        <Button
          disabled={!dateValues}
          onClick={() => {
            const dateRange = GetDateRange(type, dateValues);

            dispatch(
              getTopWeldInchesList(null, {
                start: dateRange[0],
                end: dateRange[1],
              })
            );
          }}
          icon={<ReloadOutlined />}
        ></Button>

        <Select
          style={{ minWidth: 200 }}
          mode="multiple"
          placeholder={`Filter by ${NOVARC_PRODUCT} number`}
          options={data
            .map((item) => item.id)
            .filter((v, i, a) => a.indexOf(v) === i)
            .map((item) => ({
              label: normalizeRobotName(item),
              value: item,
            }))}
          onChange={(values) => {
            setSelectedValues(values);
          }}
        ></Select>
      </Space>

      <div>
        {top_weld_inches && top_weld_inches.length > 0 && data.length > 0 ? (
          <Bar
            {...{
              data:
                !selectedValues || selectedValues.length === 0
                  ? data
                  : data.filter((v, i, a) => {
                      return selectedValues.includes(v.id);
                    }),
              xField: "value",
              yField: "id",
              barWidthRatio: 0.9,
              maxBarWidth: 20,
              label: {
                position: "middle",
                formatter: function (v) {
                  return v.value.toFixed(1);
                },
              },
              xAxis: {
                label: {
                  formatter: function formatter(v) {
                    return v;
                  },
                },
              },
              yAxis: {
                label: {
                  formatter: function formatter(v) {
                    return normalizeRobotName(v);
                  },
                },
              },
              tooltip: {
                title: (v) => normalizeRobotName(v),
                formatter: (datum) => {
                  return {
                    name: normalizeRobotName(datum.id),
                    value: datum.value && datum.value.toFixed(1) + " inches",
                  };
                },
              },
            }}
          />
        ) : (
          <Empty />
        )}
      </div>
    </>
  );
}
export default TopWeldInches;
