import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Avatar,
  Button,
  Form,
  Input,
  Modal,
  Popconfirm,
  Select,
  Table,
  Tag,
  Tooltip,
} from "antd";
import {
  EditOutlined,
  // DeleteOutlined,
  CheckOutlined,
  CloseOutlined,
  UserOutlined,
  RobotOutlined,
} from "@ant-design/icons";

import {
  getGroupsList,
  createGroup,
  getUsersList,
  getAdminRobotsList,
  updateGroupResource,
} from "./adminSlice";

import { NOVARC_PRODUCT } from "../../libs/constants";
const { Option } = Select;

// reset form fields when modal is form, closed
const useResetFormOnCloseModal = ({ form, visible }) => {
  const prevVisibleRef = useRef();
  useEffect(() => {
    prevVisibleRef.current = visible;
  }, [visible]);
  const prevVisible = prevVisibleRef.current;
  useEffect(() => {
    if (!visible && prevVisible) {
      form.resetFields();
    }
    // eslint-disable-next-line
  }, [visible]);
};

const ModalForm = ({ visible, onCreate, onCancel, errorMessage }) => {
  const [form] = Form.useForm();
  useResetFormOnCloseModal({
    form,
    visible,
  });

  const onOk = () => {
    form.submit();

    form
      .validateFields()
      .then((values) => {
        onCreate(values);
        form.resetFields();
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <Modal
      title="Add a company or group"
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
    >
      <p>Create a group by company or {NOVARC_PRODUCT} name</p>
      <Form form={form} layout="vertical" name="groupForm">
        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
            },
            {
              type: "string",
              min: 3,
              max: 30,
              message: "Name should be between 3 to 30 characters",
            },
          ]}
        >
          <Input />
        </Form.Item>
        {errorMessage && (
          <span
            style={{ color: "#ff4d4f", fontSize: "14px", lineHeight: 1.5715 }}
          >
            {"group already exists"}
          </span>
        )}
      </Form>
    </Modal>
  );
};

function SelectUsers(props) {
  const { record, users } = props;

  const defaultValues = record.users
    ? record.users.map((item) => ({
        ...item,
        label: item.email,
        value: item.id,
        key: item.id,
      }))
    : [];

  const usersOptions = users.map((item) => ({
    ...item,
    label: item.email,
    value: item.id,
    key: item.id,
  }));

  return (
    <Select
      mode="multiple"
      placeholder="Please select user(s) to add to the group"
      defaultValue={defaultValues}
      onChange={props.onChange}
      style={{ width: "100%" }}
      labelInValue
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {usersOptions.map((item) => (
        <Option key={item.id} value={item.id}>
          {item.email}
        </Option>
      ))}
    </Select>
  );
}

function SelectRobots(props) {
  const { record, robots } = props;

  const defaultValues = record.robots
    ? record.robots.map((item) => ({
        ...item,
        label: item.name,
        value: item.id,
        key: item.id,
      }))
    : [];

  const robotsOptions = robots.map((item) => ({
    ...item,
    label: item.name,
    value: item.id,
    key: item.id,
  }));

  return (
    <Select
      mode="multiple"
      placeholder="Please select user(s) to add to the group"
      defaultValue={defaultValues}
      onChange={props.onChange}
      style={{ width: "100%" }}
      labelInValue
    >
      {robotsOptions.map((item) => (
        <Option key={item.id} value={item.id}>
          {item.name}
        </Option>
      ))}
    </Select>
  );
}

function getInputNode(dataIndex, record, options) {
  switch (dataIndex) {
    case "users":
      return <SelectUsers record={record} users={options.users}></SelectUsers>;

    case "robots":
      return (
        <SelectRobots record={record} robots={options.robots}></SelectRobots>
      );

    default:
      return <Input />;
  }
}

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  options,
  ...restProps
}) => {
  const inputNode = getInputNode(dataIndex, record, options);
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={
            [
              // {
              //   required: true,
              //   message: `Please Input ${title}!`,
              //   type: dataIndex === "name" ? "string" : "array",
              // },
            ]
          }
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

function GroupsList() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const groups = useSelector((state) => state.admin.groups);
  const users = useSelector((state) => state.admin.users);
  const robots = useSelector((state) => state.admin.robots);

  const creatingGroupError = useSelector(
    (state) => state.admin.fetchingError
  );
  const [visible, setVisible] = useState(false);

  const [editingKey, setEditingKey] = useState("");

  useEffect(() => {
    dispatch(getGroupsList());
    dispatch(getUsersList());
    dispatch(getAdminRobotsList());
  }, [dispatch]);

  const isEditing = (record) => record.key === editingKey;

  if (!groups && !users && !robots) {
    return <></>;
  }
  const cancel = () => {
    setEditingKey("");
  };
  const edit = (record) => {
    console.log("edit", record);
    form.setFieldsValue({
      users: [],
      robots: [],

      ...record,
    });

    setEditingKey(record.key);
  };

  const save = async (key, id) => {
    try {
      const row = await form.validateFields();

      const { users, robots } = row;

      const usersList =
        users && users.length > 0
          ? users.map((item) => {
              if (item.value) return item.value;
              else return item.id;
            })
          : [];
      const robotsList =
        robots && robots.length > 0
          ? robots.map((item) => {
              if (item.value) return item.value;
              else return item.id;
            })
          : [];

      dispatch(
        updateGroupResource({id, requestData: { users: usersList, robots: robotsList }})
      );

      setEditingKey("");
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const handleAdd = (value) => {
    console.log("handleAdd", value);
    dispatch(createGroup(value));
    if (!!creatingGroupError) {
      hideModal();
    } else {
    }
  };

  const showModal = () => {
    setVisible(true);
  };

  const hideModal = () => {
    setVisible(false);
  };

  const columns = [
    //   {
    //     title: "Id",
    //     dataIndex: "id",
    //     key: "id",
    //   },
    {
      title: "Group Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Users",
      dataIndex: "users",
      key: "users",
      editable: true,
      render: (text, record, index) => {
        return (
          <>
            {record.users &&
              record.users.map((item) => {
                item = { ...item, value: item.id };

                return (
                  <Tooltip
                    placement="topLeft"
                    title={item.email}
                    arrowPointAtCenter
                  >
                    <Tag>
                      <Avatar size="small" icon={<UserOutlined />} />
                      <span style={{ paddingLeft: 4 }}>{item.name}</span>
                    </Tag>
                  </Tooltip>
                );
              })}
          </>
        );
      },
    },
    {
      title: "Units",
      dataIndex: "robots",
      key: "robots",
      editable: true,
      render: (text, record, index) => {
        return (
          <>
            {record.robots &&
              record.robots.map((item) => {
                item = { ...item, value: item.id };

                return (
                  <Tag>
                    <Avatar size="small" icon={<RobotOutlined />} />
                    <span style={{ paddingLeft: 4 }}>{item.name}</span>
                  </Tag>
                );
              })}
          </>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Popconfirm
              title="Sure to Save?"
              onConfirm={() => {
                save(record.key, record.id);
              }}
            >
              <Button icon={<CheckOutlined />} />
            </Popconfirm>

            <Button icon={<CloseOutlined />} onClick={cancel} />
          </span>
        ) : (
          <Button
            icon={<EditOutlined />}
            disabled={editingKey !== ""}
            onClick={() => edit(record)}
          />
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        options: { groups, users, robots },
      }),
    };
  });

  return (
    <>
      <Button
        // disabled={true}
        onClick={showModal}
        type="primary"
        style={{
          marginBottom: 16,
        }}
      >
        Add a Group
      </Button>

      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={
            groups && [
              ...groups.map((item, index) => ({ ...item, key: index })),
            ]
          }
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={{
            onChange: cancel,
          }}
        />
      </Form>

      <ModalForm
        visible={visible}
        onCreate={handleAdd}
        onCancel={hideModal}
        errorMessage={creatingGroupError}
      />
    </>
  );
}

export default GroupsList;
